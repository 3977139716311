/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer components
  .link
    @apply text-primary hf:text-primary-dark

    @media print
      @apply text-black underline font-semibold
