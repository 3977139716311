/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer components
  .video-wrapper
    max-width: 80rem
    margin: 0 auto

    .video-player
      @apply rounded-md
      background-color: #000
      position: relative
      padding-top: 56.25%
      height: 0
      overflow: hidden

      img,
      iframe,
      .play-button
        position: absolute
        display: block

      img,
      iframe
        top: 0
        left: 0
        width: 100%
        height: 100%

      .play-button
        width: 90px
        height: 90px
        background-color: #222
        z-index: 1
        opacity: 0.9
        border-radius: 100%
        cursor: pointer

        &:before
          position: absolute
          content: ""
          line-height: 0
          border-style: solid
          border-width: 15px 0 15px 26px
          border-color: transparent transparent transparent #fff

        &, &:before
          top: 50%
          left: 50%
          transform: translate3d(-50%, -50%, 0)

        &:hover, &:focus
          background-color: #000

        @media print
          display: none
