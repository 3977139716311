/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer components
  // Form fields
  .field
    @apply mb-4

    &>label
      @apply text-gray-800 font-bold

    label[for]
      @apply cursor-pointer

  // Horizontal field
  .field-h
    @apply flex items-baseline max-w-xl

    @screen max-sm
      @apply block

    & > label:first-child, & > .field-spacer:first-child
      @apply w-44 mr-2

      @screen max-sm
        @apply mr-0

  // Field with an error
  .field-err
    @apply box-content p-2 -mx-2 rounded bg-red-100 bg-opacity-80
