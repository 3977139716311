@charset "UTF-8";
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@import "@fontsource/public-sans/400.css";
@import "@fontsource/public-sans/400-italic.css";
@import "@fontsource/public-sans/600.css";
@import "@fontsource/public-sans/600-italic.css";
@import "@fontsource/public-sans/700.css";
@import "@fontsource/public-sans/700-italic.css";
@import "@fontsource/lora/400.css";
@import "@fontsource/lora/400-italic.css";
@import "@fontsource/lora/700.css";
@import "@fontsource/lora/700-italic.css";
@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer base {
  svg {
    fill: currentColor;
  }
  :focus-visible {
    @apply outline-none;
    @apply ring-2 ring-yellow-500 bg-yellow-50;
    @apply ring-offset-2 ring-offset-yellow-50;
  }
  details > summary {
    @apply cursor-pointer;
  }
  details > summary:not(.with-marker) {
    @apply list-none;
  }
  details > summary:not(.with-marker)::-webkit-details-marker {
    display: none;
  }
}
@layer base {
  * {
    scrollbar-color: #aaa #fafafa;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    width: 2px;
    border-radius: 4px;
  }
}
@layer base {
  @media print {
    html {
      font-size: 8pt;
    }
  }
  body.js input::-ms-reveal,
  body.js input::-ms-clear {
    display: none;
  }
}
@layer base {
  /* autoprefixer: off */
  @supports (contain: paint) and (not (-moz-appearance: none)) {
    input[type=password]:not(:placeholder-shown) {
      font-family: pass, sans-serif;
      letter-spacing: 1px;
    }
  }
}
@layer utilities {
  .outline-std {
    @apply outline-1 outline-dotted outline-current;
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .btn {
    @apply inline-block p-2 leading-tight whitespace-nowrap border cursor-pointer;
    @apply bg-gray-500 border-gray-500 text-white;
    @apply transition-colors duration-200;
    @apply hf:bg-gray-700 hf:border-gray-700;
  }
  .btn-outlined {
    @apply btn;
    @apply bg-transparent border-gray-600;
    @apply text-gray-600;
    @apply hf:bg-gray-600 hf:text-white hf:border-gray-600;
  }
  .btn-primary {
    @apply btn rounded;
    @apply bg-primary border-primary;
    @apply text-white;
    @apply hf:text-white hf:bg-primary-dark hf:border-primary-dark;
  }
  .btn-danger {
    @apply btn rounded;
    @apply bg-red-600 border-red-600;
    @apply text-white;
    @apply hf:text-white hf:bg-red-800 hf:border-red-800;
  }
  .btn-outlined-primary {
    @apply btn;
    @apply bg-transparent border-primary;
    @apply text-primary;
    @apply hf:bg-primary hf:text-white hf:border-primary;
  }
  .btn-outlined-danger {
    @apply btn;
    @apply bg-transparent border-red-600;
    @apply text-red-600;
    @apply hf:bg-red-600 hf:text-white hf:border-red-600;
  }
}
/* * SPDX-FileCopyrightText: © 2023 Joachim Robert <joachim.robert@protonmail.com>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .bookmark-card {
    @apply h-full overflow-hidden rounded flex flex-row;
    @apply focus-within:shadow hover:shadow;
  }
  @screen lg {
    .bookmark-card {
      @apply flex-col;
    }
  }
  .bookmark-card > .bookmark-card-top {
    @apply relative;
  }
  .bookmark-card > .bookmark-card-top > .svgicon {
    @apply absolute z-10 top-1 right-1 flex items-center justify-center;
    @apply w-6 h-6 rounded-full;
    @apply bg-black bg-opacity-25 text-white;
  }
  .bookmark-card > .bookmark-card-top > .bookmark-card-image {
    @apply block relative overflow-hidden h-full w-28;
  }
  @screen md {
    .bookmark-card > .bookmark-card-top > .bookmark-card-image {
      @apply w-36;
    }
  }
  @screen lg {
    .bookmark-card > .bookmark-card-top > .bookmark-card-image {
      @apply pt-16/9 h-0 w-auto;
    }
  }
  .bookmark-card > .bookmark-card-top > .bookmark-card-image > img {
    @apply absolute top-0 h-full w-full object-cover object-center filter;
  }
  .bookmark-card > .bookmark-card-content {
    @apply flex-grow p-2 flex flex-col;
  }
  .bookmark-card > .bookmark-card-content > h3 {
    @apply mb-2 leading-tight font-semibold;
  }
  .bookmark-card > .bookmark-card-content > h3 > a {
    @apply inline-block hf:text-primary-dark;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-meta {
    @apply flex items-center gap-2 mb-2 text-xs text-gray-600;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-meta > img {
    @apply h-4 w-auto filter grayscale opacity-50;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-meta > strong {
    @apply leading-none overflow-hidden text-ellipsis;
  }
  .bookmark-card > .bookmark-card-content > ul {
    @apply inline-flex flex-wrap justify-start gap-x-1 gap-y-3;
  }
  .bookmark-card > .bookmark-card-content > ul > li > a {
    @apply text-sm bg-gray-100 leading-none py-1 px-1.5 border rounded-md;
    @apply hf:bg-yellow-100;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions {
    @apply flex flex-grow items-end mt-2 text-lg;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions form {
    @apply text-gray-200;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions button {
    @apply fill-current hf:text-primary-dark;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions button[name=is_marked][value="0"], .bookmark-card > .bookmark-card-content > .bookmark-card-actions button[name=is_archived][value="0"] {
    @apply text-primary;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions button[name=is_deleted] {
    @apply hf:text-red-800;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions .bookmark-card-deleted {
    @apply flex flex-grow items-center;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions .bookmark-card-deleted > span {
    @apply flex-grow text-red-700 text-xs font-semibold;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions .bookmark-card-deleted button {
    @apply btn-primary whitespace-nowrap text-sm py-1 ml-2;
  }
  .bookmark-card > .bookmark-card-content > .bookmark-card-actions .bookmark-card-deleted button svg {
    @apply fill-current;
  }
  .bookmark-card:hover .bookmark-card-top > .bookmark-card-image, .bookmark-card:focus-within .bookmark-card-top > .bookmark-card-image {
    @apply contrast-105 brightness-95;
  }
  .bookmark-card:hover .bookmark-card-content > h3, .bookmark-card:focus-within .bookmark-card-content > h3 {
    @apply text-primary;
  }
  .bookmark-card:hover .bookmark-card-meta > img, .bookmark-card:focus-within .bookmark-card-meta > img {
    @apply filter-none opacity-100;
  }
  .bookmark-card:hover .bookmark-card-actions form button, .bookmark-card:focus-within .bookmark-card-actions form button {
    @apply text-primary;
  }
  .bookmark-card:hover .bookmark-card-actions form button[name=is_deleted], .bookmark-card:focus-within .bookmark-card-actions form button[name=is_deleted] {
    @apply text-red-700;
  }
  .bookmark-card:hover .bookmark-card-actions .bookmark-card-deleted form button[name=is_deleted], .bookmark-card:focus-within .bookmark-card-actions .bookmark-card-deleted form button[name=is_deleted] {
    @apply text-white;
  }
  .bookmark-card[data-deleted=true] .bookmark-card-top,
  .bookmark-card[data-deleted=true] .bookmark-card-content h3,
  .bookmark-card[data-deleted=true] .bookmark-card-content ul,
  .bookmark-card[data-deleted=true] .bookmark-card-meta {
    @apply opacity-50;
  }
  .bookmark-card[data-loading=true] .bookmark-card-content {
    @apply flex-row flex-grow flex gap-2 px-2 items-center;
  }
  @screen lg {
    .bookmark-card[data-loading=true] .bookmark-card-content {
      @apply flex-col;
    }
  }
  .bookmark-card[data-loading=true] .bookmark-card-content > div {
    @apply order-2 text-4xl text-center py-4;
  }
  @screen lg {
    .bookmark-card[data-loading=true] .bookmark-card-content > div {
      @apply order-none flex-grow py-6;
    }
  }
  @screen max-sm {
    .bookmark-card[data-loading=true] .bookmark-card-content > div {
      @apply flex-grow;
    }
  }
  .bookmark-card[data-loading=true] .bookmark-card-content > p {
    @apply flex-grow text-sm font-semibold;
  }
  @screen lg {
    .bookmark-card[data-loading=true] .bookmark-card-content > p {
      @apply flex-grow-0;
    }
  }
  @screen max-sm {
    .bookmark-card[data-loading=true] .bookmark-card-content > p {
      @apply hidden;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .field {
    @apply mb-4;
  }
  .field > label {
    @apply text-gray-800 font-bold;
  }
  .field label[for] {
    @apply cursor-pointer;
  }
  .field-h {
    @apply flex items-baseline max-w-xl;
  }
  @screen max-sm {
    .field-h {
      @apply block;
    }
  }
  .field-h > label:first-child, .field-h > .field-spacer:first-child {
    @apply w-44 mr-2;
  }
  @screen max-sm {
    .field-h > label:first-child, .field-h > .field-spacer:first-child {
      @apply mr-0;
    }
  }
  .field-err {
    @apply box-content p-2 -mx-2 rounded bg-red-100 bg-opacity-80;
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .svgicon {
    display: inline;
    line-height: 1;
    position: relative;
    height: 1em;
    top: -0.09em;
  }
  .svgicon svg {
    height: 1em;
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer utilities {
  .layout-topbar {
    @apply flex-shrink-0;
    @apply h-topnav;
  }
  .layout {
    @apply min-h-screen grid;
    grid-template-columns: theme("spacing.col-1") theme("spacing.col-5") minmax(auto, theme("spacing.col-23"));
  }
  @screen max-sm {
    .layout {
      @apply flex flex-col;
    }
  }
  @media print {
    .layout {
      @apply block;
    }
  }
  .layout--bookmark {
    grid-template-columns: theme("spacing.col-1") auto;
  }
  .layout-topnav {
    @apply flex flex-col flex-shrink-0 gap-2;
    @apply box-border py-2 w-col-1 h-screen;
    @apply sticky top-0 z-20;
    @apply bg-gray-200 text-black;
  }
  @screen max-sm {
    .layout-topnav {
      @apply flex-row w-full h-topnav px-2;
    }
  }
  @media print {
    .layout-topnav {
      @apply hidden;
    }
  }
  .layout-topnav .logo {
    @apply flex items-center justify-center;
    @apply layout-topbar;
  }
  @screen max-sm {
    .layout-topnav .logo {
      @apply h-auto;
    }
  }
  .layout-topnav .mainmenu {
    @apply flex-1 overflow-y-auto;
    @apply flex flex-col items-center gap-2;
  }
  @screen max-sm {
    .layout-topnav .mainmenu {
      @apply flex-row;
    }
  }
  .layout-topnav .mainmenu menu {
    @apply flex flex-col gap-3;
  }
  @screen max-sm {
    .layout-topnav .mainmenu menu {
      @apply flex-row gap-2;
    }
  }
  .layout-topnav .mainmenu menu a, .layout-topnav .mainmenu menu button {
    @apply block;
    @apply rounded;
    @apply p-3;
    @apply text-gray-900 bg-black bg-opacity-5;
    @apply hf:bg-opacity-10;
    @apply data-current:bg-yellow-400 data-current:text-yellow-800 data-current:bg-opacity-50;
  }
  @screen max-xs {
    .layout-topnav .mainmenu menu a, .layout-topnav .mainmenu menu button {
      @apply px-2;
    }
  }
  .layout-topnav .mainmenu menu a .svgicon svg, .layout-topnav .mainmenu menu button .svgicon svg {
    @apply w-5 h-5;
  }
  .a11y-nav {
    @apply absolute -top-80 z-50 w-full;
  }
  .a11y-nav a {
    @apply absolute left-0 right-0 transition-[top];
    @apply bg-white bg-opacity-95 font-bold;
    @apply p-4 text-center;
    @apply hf:top-80 hf:shadow-lg;
  }
  .sidemenu {
    @apply flex-shrink-0 sticky top-0 z-30 h-screen overflow-y-auto shadow-sidebar-l bg-gradient-to-r from-gray-50 to-gray-100 px-4;
  }
  @screen max-sm {
    .sidemenu {
      @apply w-full;
    }
  }
  @media print {
    .sidemenu {
      @apply hidden;
    }
  }
  .sidemenu menu a {
    @apply hf:text-primary data-current:font-bold data-current:text-primary-dark;
    @apply block leading-none py-1 pl-1 mb-1 border-l-2 border-transparent hf:border-primary data-current:border-primary-dark;
  }
  @screen max-md {
    .sidemenu menu a {
      @apply mb-2 text-lg;
    }
  }
  .sidemenu menu a .svgicon svg {
    @apply w-5 h-5;
  }
  @screen max-sm {
    body.js .sidemenu {
      @apply fixed bottom-0 left-0 top-auto;
    }
  }
  @screen max-sm {
    body.js .sidemenu--hidden {
      @apply hidden;
    }
  }
  @screen max-sm {
    body.no-js .sidemenu {
      @apply relative h-auto order-last;
    }
  }
  body.no-js button[aria-controls=sidemenu] {
    @apply hidden;
  }
  .layout-content {
    @apply bg-transparent px-6 py-8;
  }
  @screen max-sm {
    .layout-content {
      @apply pb-16;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .link {
    @apply text-primary hf:text-primary-dark;
  }
  @media print {
    .link {
      @apply text-black underline font-semibold;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@prose {
  line-height: 1.5;
}
@layer components {
  .prose-grid {
    background-image: linear-gradient(#F5D6F5 1px, transparent 1px);
    background-size: 100% 1.5em;
  }
  .prose h1 {
    @flow-block;
    font-size: 1.8em;
  }
  .prose h2 {
    @flow-block;
    font-size: 1.6em;
  }
  .prose h3 {
    @flow-block;
    font-size: 1.4em;
  }
  .prose h4 {
    @flow-block;
    font-size: 1.2em;
  }
  .prose h5 {
    @flow-block;
    font-size: 1em;
  }
  .prose h6 {
    @flow-block;
    font-size: 0.9em;
  }
  .prose p, .prose blockquote, .prose address, .prose figure, .prose hr {
    @flow-block;
    padding: 0;
  }
  .prose p, .prose li, .prose dd {
    hyphens: auto;
  }
  .prose strong, .prose time, .prose b {
    font-weight: bold;
  }
  .prose a, .prose a:visited {
    @apply text-primary;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: theme("colors.gray.400");
  }
  @media print {
    .prose a, .prose a:visited {
      color: #000;
      font-weight: 600;
      text-decoration: underline;
    }
  }
  .prose a:focus, .prose a:hover, .prose a:active {
    @apply text-primary-dark;
    text-decoration: underline;
  }
  .prose h1 > a[href^="#"], .prose h1 > a[href^="#"]:visited, .prose h2 > a[href^="#"], .prose h2 > a[href^="#"]:visited, .prose h3 > a[href^="#"], .prose h3 > a[href^="#"]:visited, .prose h4 > a[href^="#"], .prose h4 > a[href^="#"]:visited, .prose h5 > a[href^="#"], .prose h5 > a[href^="#"]:visited, .prose h6 > a[href^="#"], .prose h6 > a[href^="#"]:visited {
    color: inherit;
    text-decoration: none;
  }
  .prose em, .prose dfn, .prose i {
    font-style: italic;
  }
  .prose sub, .prose sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  .prose sup {
    top: -0.5em;
  }
  .prose sub {
    bottom: -0.25em;
  }
  .prose small {
    font-size: 80%;
  }
  .prose blockquote {
    padding: 1.5em;
    padding: calc(var(--prose-lh) * 1em);
    background: rgba(0, 0, 0, 0.03);
    border-left: 5px solid theme("colors.gray.300");
  }
  .prose blockquote *:last-child {
    margin-bottom: 0;
  }
  .prose cite {
    font-style: italic;
  }
  .prose q:before {
    content: open-quote;
  }
  .prose q:after {
    content: close-quote;
  }
  .prose pre {
    @flow-block;
    padding: 1.6666666667em;
    padding: calc(var(--prose-lh) * 1em - 1px) calc(var(--prose-lh) * 1em);
    font-size: 0.9em;
    border: 1px solid theme("colors.gray.300");
    background: rgba(0, 0, 0, 0.03);
    white-space: pre-wrap;
    overflow-x: auto;
  }
  .prose pre code {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
  }
  .prose code, .prose kbd, .prose samp, .prose var {
    font-size: 0.875em;
    line-height: 1;
    padding: 1px 3px;
    border-radius: theme("borderRadius.sm");
    background-color: rgba(0, 0, 0, 0.04);
  }
  .prose mark {
    line-height: 1;
    padding: 1px 3px;
    background-color: theme("colors.yellow.300");
  }
  .prose img, .prose figure {
    max-width: 100%;
  }
  .prose img {
    @apply rounded;
  }
  .prose figure {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .prose figure img, .prose figure svg, .prose figure pre {
    display: block;
    margin: 0 auto;
  }
  .prose figure figcaption {
    @vertical-flow;
    font-size: 0.9em;
  }
  .prose figure *:last-child {
    margin-bottom: 0;
  }
  .prose ul, .prose ol, .prose dl {
    @flow-block;
    padding: 0;
  }
  .prose ul, .prose ol {
    list-style-position: outside;
    padding-left: 2em;
  }
  .prose ul {
    list-style-type: disc;
  }
  .prose ul > li::marker {
    font-size: 0.9em;
  }
  .prose ol {
    list-style-type: decimal;
  }
  .prose ol > li::marker {
    font-size: 0.75em;
    font-weight: 600;
  }
  .prose li p, .prose li ul, .prose li ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  .prose li img {
    display: inline-block;
  }
  .prose dl dt {
    font-weight: bold;
  }
  .prose dl dd {
    margin: 0 0 0 1.5em;
    padding: 0;
  }
  .prose table {
    @flow-block;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: -2px;
  }
  .prose caption {
    color: theme("colors.gray.800");
    font-style: italic;
    margin-bottom: 0;
  }
  .prose td, .prose th {
    @vertical-flow;
    padding: 0.375em;
    padding: calc(var(--prose-line-height) / 4 * 1em - 0.5px);
    vertical-align: top;
    min-width: 2em;
    text-align: left;
    border: 1px solid theme("colors.gray.400");
  }
  .prose th {
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .prose thead tr:last-child th {
    border-bottom-color: theme("colors.gray.700");
  }
  .prose tfoot td, .prose tfoot th {
    font-style: italic;
  }
  @media print {
    .prose img {
      max-height: 50vh;
      width: auto;
      display: inline;
    }
    .prose figure {
      display: block;
      text-align: center;
    }
    .prose figure img, .prose figure svg {
      display: inline;
    }
    .prose figcaption {
      text-align: left;
    }
    .prose blockquote {
      text-align: left;
      background: none;
      padding-block: 0;
    }
    .prose img, .prose blockquote, .prose figure, .prose p:has(img) {
      break-inside: avoid;
      break-before: auto;
      break-after: auto;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .title {
    @apply mb-4 font-semibold text-gray-700;
  }
}
/* * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .video-wrapper {
    max-width: 80rem;
    margin: 0 auto;
  }
  .video-wrapper .video-player {
    @apply rounded-md;
    background-color: #000;
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }
  .video-wrapper .video-player img,
  .video-wrapper .video-player iframe,
  .video-wrapper .video-player .play-button {
    position: absolute;
    display: block;
  }
  .video-wrapper .video-player img,
  .video-wrapper .video-player iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-wrapper .video-player .play-button {
    width: 90px;
    height: 90px;
    background-color: #222;
    z-index: 1;
    opacity: 0.9;
    border-radius: 100%;
    cursor: pointer;
  }
  .video-wrapper .video-player .play-button:before {
    position: absolute;
    content: "";
    line-height: 0;
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
  }
  .video-wrapper .video-player .play-button, .video-wrapper .video-player .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .video-wrapper .video-player .play-button:hover, .video-wrapper .video-player .play-button:focus {
    background-color: #000;
  }
  @media print {
    .video-wrapper .video-player .play-button {
      display: none;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2023 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .bookmark-grid {
    @apply grid;
    grid-template-columns: minmax(auto, theme("spacing.col-22")) theme("spacing.col-6");
  }
  @screen max-md {
    .bookmark-grid {
      @apply flex flex-col;
      @apply min-h-screen;
    }
  }
  @screen max-sm {
    .bookmark-grid {
      min-height: calc(100vh - theme("spacing.topnav"));
    }
  }
  @media print {
    .bookmark-grid {
      @apply block;
    }
  }
  .bookmark-content {
    @apply col-start-1;
    @apply row-start-1;
    @apply bg-transparent;
    @apply max-w-col-22 px-6 pb-8;
  }
  @screen md {
    .bookmark-content {
      @apply min-h-screen;
    }
  }
  @screen max-md {
    .bookmark-content {
      @apply px-4 grow;
    }
  }
  @media print {
    .bookmark-content {
      @apply overflow-visible;
      @apply max-w-none;
      @apply px-0;
    }
  }
  .bookmark-content rd-annotation {
    @apply bg-yellow-100;
    @apply border-b-yellow-400 border-b;
  }
  @media print {
    .bookmark-content .bookmark-article {
      columns: 2;
      column-gap: 2rem;
      orphans: 5;
      widows: 5;
      height: 100%;
      column-fill: auto;
    }
    .bookmark-content .bookmark-article img {
      width: auto;
      display: inline;
    }
    .bookmark-content .bookmark-article img {
      break-inside: avoid;
      break-before: auto;
      break-after: auto;
    }
  }
  @media print {
    .bookmark-content .bookmark-article .prose p, .bookmark-content .bookmark-article .prose li, .bookmark-content .bookmark-article .prose dd {
      text-align: justify;
    }
  }
  .bookmark-sidebar {
    @apply col-start-2;
    @apply row-start-1;
    @apply w-col-6 h-screen sticky overflow-y-auto z-30;
    @apply border-l border-l-gray-200;
  }
  @screen md {
    .bookmark-sidebar {
      @apply top-0;
    }
  }
  @screen max-md {
    .bookmark-sidebar {
      @apply order-last;
      @apply bottom-0 bg-white;
      @apply w-full h-auto;
      @apply overflow-y-hidden;
      @apply flex flex-col;
      @apply shadow-panel-t;
      @apply transition-all ease-in-out;
    }
  }
  @media print {
    .bookmark-sidebar {
      @apply static max-w-none w-full h-auto overflow-visible;
      @apply mb-8 border-b border-l-0;
    }
  }
  @screen max-md {
    body.js .bookmark-sidebar {
      max-height: 60vh;
    }
    body.js .bookmark-sidebar .panel--hidden {
      @apply hidden;
    }
  }
  body.js .bookmark-sidebar [aria-expanded=true][aria-controls=bookmark-panel] svg {
    @apply rotate-180;
  }
  @screen max-md {
    body.no-js .bookmark-sidebar {
      @apply relative;
    }
  }
}
/* * SPDX-FileCopyrightText: © 2023 Olivier Meunier <olivier@neokraft.net>
 * *
 * * SPDX-License-Identifier: AGPL-3.0-only
 * */
@layer components {
  .prose.docs p, .prose.docs li, .prose.docs dd {
    hyphens: none;
  }
  .prose.docs a {
    font-weight: 600;
  }
  .prose.docs h2 {
    @flow-block;
    font-size: 1.6em;
    font-weight: 500;
    color: theme("colors.gray.700");
    border-bottom: 1px solid theme("colors.gray.200");
    position: relative;
    padding-bottom: 5px;
    top: -5px;
  }
  .prose.docs h3 {
    color: theme("colors.yellow.700");
  }
  .prose.docs figure img {
    border: 1px solid theme("colors.gray.200");
    padding: 0.25rem;
  }
  .prose.docs figure figcaption {
    text-align: center;
    margin-top: 0.25rem;
  }
}
@layer components {
  rapi-doc {
    --font-regular: theme("fontFamily.sans");
    --font-mono: theme("fontFamily.mono");
    --bg: theme("colors.gray.50");
    --light-fg: theme("colors.gray.700");
    --blue: theme("colors.primary.DEFAULT");
    --primary-color: theme("colors.primary.DEFAULT");
    --primary-color-invert: theme("colors.white");
    --nav-accent-color: theme("colors.yellow.400");
  }
  rapi-doc::part(section-navbar) {
    background: linear-gradient(to right, theme("colors.gray.50"), theme("colors.gray.100"));
    box-shadow: theme("boxShadow.sidebar-l");
    width: theme("spacing.col-5");
  }
  rapi-doc::part(section-navbar-operations-top) {
    display: none;
  }
  rapi-doc::part(section-navbar-scroll) {
    padding: 0 theme("padding.4");
  }
  rapi-doc::part(section-navbar-item) {
    border-left-width: 2px;
    font-weight: theme("fontWeight.medium");
  }
  rapi-doc::part(section-navbar-active-item) {
    border-left-color: theme("colors.primary.dark");
    border-radius: 0;
  }
  rapi-doc::part(label-operation-method) {
    font-weight: bolder;
  }
  rapi-doc::part(label-operation-path) {
    font-weight: bold;
    font-size: 1.2em;
  }
  rapi-doc::part(btn) {
    box-shadow: none;
    border-radius: theme("borderRadius.DEFAULT");
  }
  rapi-doc::part(textbox) {
    border-radius: theme("borderRadius.DEFAULT");
  }
}