/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@use "sass:math"

$line-height: 1.5
@prose
  line-height: $line-height

@layer components
  .prose-grid
    background-image: linear-gradient(#F5D6F5 1px, transparent 1px)
    background-size: 100% $line-height*1em

  .prose
    h1
      @flow-block
      font-size: 1.8em

    h2
      @flow-block
      font-size: 1.6em

    h3
      @flow-block
      font-size: 1.4em

    h4
      @flow-block
      font-size: 1.2em

    h5
      @flow-block
      font-size: 1em

    h6
      @flow-block
      font-size: 0.9em

    p, blockquote, address, figure, hr
      @flow-block
      padding: 0

    p, li, dd
      hyphens: auto

    strong, time, b
      font-weight: bold

    a, a:visited
      @apply text-primary
      text-decoration-line: underline
      text-decoration-style: dotted
      text-decoration-color: theme('colors.gray.400')

      @media print
        color: #000
        font-weight: 600
        text-decoration: underline

    a:focus, a:hover, a:active
      @apply text-primary-dark
      text-decoration: underline

    h1, h2, h3, h4, h5, h6
      & > a[href^='#'], & > a[href^='#']:visited
        color: inherit
        text-decoration: none

    em, dfn, i
      font-style: italic

    sub, sup
      font-size: 75%
      line-height: 0
      position: relative
      vertical-align: baseline

    sup
      top: -.5em

    sub
      bottom: -.25em

    small
      font-size: 80%

    blockquote
      padding: $line-height * 1em
      padding: calc(var(--prose-lh) * 1em)
      background: rgba(0,0,0,.03)
      border-left: 5px solid theme('colors.gray.300')

      *:last-child
        margin-bottom: 0

    cite
      font-style: italic

    q:before
      content: open-quote
    q:after
      content: close-quote

    pre
      @flow-block
      padding: math.div($line-height, 0.9) * 1em
      padding: calc(var(--prose-lh) * 1em - 1px) calc(var(--prose-lh) * 1em)
      font-size: 0.9em
      border: 1px solid theme('colors.gray.300')
      background: rgba(0,0,0,.03)
      white-space: pre-wrap
      overflow-x: auto

      code
        padding: 0
        border: 0
        background-color: transparent
        color: inherit

    code, kbd, samp, var
      font-size: 0.875em
      line-height: 1
      padding: 1px 3px
      border-radius: theme('borderRadius.sm')
      background-color: rgba(0,0,0,.04)

    mark
      line-height: 1
      padding: 1px 3px
      background-color: theme('colors.yellow.300')

    img, figure
      max-width: 100%

    img
      @apply rounded

    figure
      display: inline-block
      width: auto
      margin-left: auto
      margin-right: auto

      img, svg, pre
        display: block
        margin: 0 auto

      figcaption
        @vertical-flow
        font-size: 0.9em

      *:last-child
        margin-bottom: 0

    ul, ol, dl
      @flow-block
      padding: 0

    ul, ol
      list-style-position: outside
      padding-left: 2em

    ul
      list-style-type: disc

      &>li::marker
        font-size: 0.9em

    ol
      list-style-type: decimal
      &>li::marker
        font-size: 0.75em
        font-weight: 600

    li
      p, ul, ol
        margin-top: 0
        margin-bottom: 0

      img
        display: inline-block

    dl
      dt
        font-weight: bold
      dd
        margin: 0 0 0 $line-height*1em
        padding: 0


    table
      @flow-block
      table-layout: fixed
      border-collapse: collapse
      border-spacing: 0
      margin-top: -2px

    caption
      color: theme('colors.gray.800')
      font-style: italic
      margin-bottom: 0

    td, th
      @vertical-flow
      padding: $line-height *.25em
      padding: calc((var(--prose-line-height) / 4) * 1em - 0.5px)
      vertical-align: top
      min-width: 2em
      text-align: left
      border: 1px solid theme('colors.gray.400')

    th
      font-weight: bold
      background-color: rgba(0,0,0,.03)

    thead tr:last-child th
      border-bottom-color: theme('colors.gray.700')

    tfoot
      td, th
        font-style: italic

    @media print
      img
        max-height: 50vh
        width: auto
        display: inline

      figure
        display: block
        text-align: center

        img, svg
          display: inline

      figcaption
        text-align: left

      blockquote
        text-align: left
        background: none
        padding-block: 0

      img, blockquote, figure, p:has(img)
        break-inside: avoid
        break-before: auto
        break-after: auto
