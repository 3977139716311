/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer base
  svg
    fill: currentColor

  :focus-visible
    @apply outline-none
    @apply ring-2 ring-yellow-500 bg-yellow-50
    @apply ring-offset-2 ring-offset-yellow-50

  details>summary
    @apply cursor-pointer

    &:not(.with-marker)
      @apply list-none
      &::-webkit-details-marker
        display: none

@layer base
  *
    scrollbar-color: #aaa #fafafa

  ::-webkit-scrollbar
    width: 5px

  ::-webkit-scrollbar-track
    background: #fafafa
    border-radius: 4px

  ::-webkit-scrollbar-thumb
    background-color: #bbb
    width: 2px
    border-radius: 4px

@layer base
  @media print
    html
      font-size: 8pt

  // JS enabled CSS rules
  body.js
    input::-ms-reveal,
    input::-ms-clear
      display: none


@layer base
  /* autoprefixer: off */
  @supports (contain: paint) and (not (-moz-appearance: none))
    input[type="password"]:not(:placeholder-shown)
      font-family: pass,sans-serif
      letter-spacing: 1px

@layer utilities
  .outline-std
    @apply outline-1 outline-dotted outline-current
