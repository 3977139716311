/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer components
  .svgicon
    display: inline
    line-height: 1
    position: relative
    height: 1em
    top: -.09em

    svg
      height: 1em
