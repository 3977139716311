/*
 * SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer utilities
  .layout-topbar
    @apply flex-shrink-0
    @apply h-topnav

  .layout
    @apply min-h-screen grid
    grid-template-columns: theme("spacing.col-1") theme("spacing.col-5") minmax(auto, theme("spacing.col-23"))

    @screen max-sm
      @apply flex flex-col

    @media print
      @apply block

  .layout--bookmark
    grid-template-columns: theme("spacing.col-1") auto

  .layout-topnav
    @apply flex flex-col flex-shrink-0 gap-2
    @apply box-border py-2 w-col-1 h-screen
    @apply sticky top-0 z-20
    @apply bg-gray-200 text-black

    @screen max-sm
      @apply flex-row w-full h-topnav px-2

    @media print
      @apply hidden

    .logo
      @apply flex items-center justify-center
      @apply layout-topbar

      @screen max-sm
        @apply h-auto

    .mainmenu
      @apply flex-1 overflow-y-auto
      @apply flex flex-col items-center gap-2

      @screen max-sm
        @apply flex-row

      menu
        @apply flex flex-col gap-3

        @screen max-sm
          @apply flex-row gap-2

        a, button
          @apply block
          @apply rounded
          @apply p-3
          @apply text-gray-900 bg-black bg-opacity-5
          @apply hf:bg-opacity-10
          @apply data-current:bg-yellow-400 data-current:text-yellow-800 data-current:bg-opacity-50

          @screen max-xs
            @apply px-2

          .svgicon svg
            @apply w-5 h-5

  .a11y-nav
      @apply absolute -top-80 z-50 w-full

      a
        @apply absolute left-0 right-0 transition-[top]
        @apply bg-white bg-opacity-95 font-bold
        @apply p-4 text-center
        @apply hf:top-80 hf:shadow-lg


  .sidemenu
    @apply flex-shrink-0 sticky top-0 z-30 h-screen overflow-y-auto shadow-sidebar-l bg-gradient-to-r from-gray-50 to-gray-100 px-4

    @screen max-sm
      @apply w-full

    @media print
      @apply hidden

    menu a
      @apply hf:text-primary data-current:font-bold data-current:text-primary-dark
      @apply block leading-none py-1 pl-1 mb-1 border-l-2 border-transparent hf:border-primary data-current:border-primary-dark

      @screen max-md
        @apply mb-2 text-lg

      .svgicon svg
        @apply w-5 h-5

  body.js .sidemenu
    @screen max-sm
      @apply fixed bottom-0 left-0 top-auto

  body.js .sidemenu--hidden
    @screen max-sm
      @apply hidden

  body.no-js .sidemenu
    @screen max-sm
      @apply relative h-auto order-last

  body.no-js button[aria-controls="sidemenu"]
    @apply hidden

  .layout-content
    @apply bg-transparent px-6 py-8

    @screen max-sm
      @apply pb-16
