/*
 * SPDX-FileCopyrightText: © 2023 Joachim Robert <joachim.robert@protonmail.com>
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@layer components

  .bookmark-card
    @apply h-full overflow-hidden rounded flex flex-row
    @apply focus-within:shadow hover:shadow

    @screen lg
      @apply flex-col

    > .bookmark-card-top
      @apply relative

      > .svgicon
        @apply absolute z-10 top-1 right-1 flex items-center justify-center
        @apply w-6 h-6 rounded-full
        @apply bg-black bg-opacity-25 text-white

      > .bookmark-card-image
        @apply block relative overflow-hidden h-full w-28

        @screen md
          @apply w-36

        @screen lg
          @apply pt-16/9 h-0 w-auto


        > img
          @apply absolute top-0 h-full w-full object-cover object-center filter


    > .bookmark-card-content
      @apply flex-grow p-2 flex flex-col

      > h3
        @apply mb-2 leading-tight font-semibold

        > a
          @apply inline-block hf:text-primary-dark


      > .bookmark-card-meta
        @apply flex items-center gap-2 mb-2 text-xs text-gray-600

        > img
          @apply h-4 w-auto filter grayscale opacity-50

        > strong
          @apply leading-none overflow-hidden text-ellipsis

      > ul
        @apply inline-flex flex-wrap justify-start gap-x-1 gap-y-3

        > li > a
          @apply text-sm bg-gray-100 leading-none py-1 px-1.5 border rounded-md
          @apply hf:bg-yellow-100

      > .bookmark-card-actions
        @apply flex flex-grow items-end mt-2 text-lg

        form
          @apply text-gray-200

        button
          @apply fill-current hf:text-primary-dark

          &[name="is_marked"][value="0"],
          &[name="is_archived"][value="0"]
            @apply text-primary

          &[name="is_deleted"]
            @apply hf:text-red-800

        .bookmark-card-deleted
          @apply flex flex-grow items-center

          & > span
            @apply flex-grow text-red-700 text-xs font-semibold

          button
            @apply btn-primary whitespace-nowrap text-sm py-1 ml-2
            svg
              @apply fill-current


    // Hover and focus on the card
    &:hover,
    &:focus-within
      .bookmark-card-top
         > .bookmark-card-image
          @apply contrast-105 brightness-95

      .bookmark-card-content
        > h3
          @apply text-primary

      .bookmark-card-meta
        > img
           @apply filter-none opacity-100

      .bookmark-card-actions
        form button
          @apply text-primary

          &[name="is_deleted"]
            @apply text-red-700

        .bookmark-card-deleted
          form button
            &[name="is_deleted"]
              @apply text-white

    // Card in deleted state
    &[data-deleted="true"]
      .bookmark-card-top,
      .bookmark-card-content h3,
      .bookmark-card-content ul,
      .bookmark-card-meta
        @apply opacity-50

    // Card while a bookmark is loading (with a spinner and text)
    &[data-loading="true"]
      .bookmark-card-content
        @apply flex-row flex-grow flex gap-2 px-2 items-center

        @screen lg
          @apply flex-col

        // spinner
        & > div
          @apply order-2 text-4xl text-center py-4

          @screen lg
            @apply order-none flex-grow py-6

          @screen max-sm
            @apply flex-grow

        // text
        & > p
          @apply flex-grow text-sm font-semibold

          @screen lg
              @apply flex-grow-0

          @screen max-sm
            @apply hidden
